import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';

import VueMoment from 'vue-moment';
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
import VModal from 'vue-js-modal';
import Vuelidate from 'vuelidate';
import Notifications from 'vue-notification';
import Paginate from 'vuejs-paginate';

Vue.component('paginate', Paginate);
Vue.component("simplebar", simplebar);

Vue.use(VModal);
Vue.use(VueMoment);
Vue.use(Vuelidate);
Vue.use(Notifications);

Vue.config.productionTip = false;

export const eventBus = new Vue();

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app');

