export const baseUrl = 'https://cwplatform.herokuapp.com/';

export const routes = {
  signUp: '/auth/signup',

  login: '/auth/signin',
  logout: '/auth/logout',

  userInfo: '/user/info',
  user: '/user',
  getAllUsers: '/user/all',
  deleteUser: id => `/user/${id}`,
  changeUserRole: id => `/user/${id}/role`,
  changeUserPass: id => `/user/${id}/password`,
  changeUserLang: id => `/user/${id}/language`,
  userSessions: id => `/user/${id}/sessions`,
  userNotes: id => `/user/${id}/notes`,
  addUserNote: id => `/user/${id}/note`,
  subUsers: '/user/visible',

  getAllArticles: '/text/all',
  articles: '/text',
  articlesUser: id => `/text/user/${id}`,
  getArticle: id => `/text/id/${id}`,
  getDeletedArticles: '/text/trashbox',
  getLastUpdates: '/text/last_updates',
  readyArticles: '/text/ready',
  setArticleColor: '/text/color',
  toggleLockArticle: '/text/lock',
  setArticleKeywords: id => `/text/${id}/keywords`,
  articleComments: id => `/text/${id}/comments`,
  setArticleComment: id => `/text/${id}/comment`,
  deletedArticles: '/text/trashbox',
  deletePermanently: '/text/permanently',
  restoreArticles: '/text/restore',
  publicArticles: '/text/pub',
  lastComments: '/text/last_comments',
  lastChanges: '/text/last_changes',
  copyArticles: '/text/copy',
  moveArticles: '/text/move',
  getArticleRevisions: id => `/text/${id}/revisions`,
  setArticleRevision: id => `/text/revision/${id}/active`,
  checkArticle: '/text/local_metrics',
  textruBalance: '/text/balance'
};

