import axios from '@/api/axios';
import {routes} from '@/api/config';
import router from '../../router';

const tokens = JSON.parse(localStorage.getItem('tokens'));
const user = JSON.parse(localStorage.getItem('prevUser')) || {};

const state = {
  isLogged: tokens ? true : false,
  user,
  prevUser: {}
};
const getters = {
  user: (state) => {
    return state.user
  },
  prevUser: (state) => {
    return state.prevUser
  },
  isLogged: (state) => {
    return state.isLogged
  },
};
const mutations = {
  setLogged(state, data) {
    state.isLogged = true;
  },
  setUser(state, data) {
    state.user = data;
  },
  setPrevUser(state, data) {
    state.prevUser = data;
  },
  logout(state) {
    state.selectedUser = null;
    state.isLogged = false;
    state.user = {};
  }
};
const actions = {
  changeTokens: ({commit}, tokens) => {
    localStorage.setItem('tokens', JSON.stringify(tokens));
  },
  logout: async ({commit}) => {
    await axios().post(routes.logout).then(async () => {
      localStorage.clear();
      commit('logout');
      await router.replace('/login');
    }).catch(async err => {
      localStorage.clear();
      commit('logout');
      await router.replace('/login');
      console.error('Error logout:', err);
    });

  },
  login: ({commit, dispatch}, {password, email}) => {
    return new Promise((resolve, reject) => {
      axios().post(routes.login, {password, email}).then(async resp => {
        const {accessToken, refreshToken, email, role, id, ip, loginData} = resp.data.data;
        localStorage.setItem('tokens', JSON.stringify({accessToken, refreshToken}));
        commit('setLogged');
        commit('setUser', {email, role, id, ip, loginData});

        await router.push({name: 'Articles'});
        resolve(resp.data);
      }).catch(error => {
        reject(error);
      });
    });
  },
  signUp: async ({commit}, {password, email, role}) => {
    return new Promise((resolve, reject) => {
      axios().post(routes.signUp, {password, email, role}).then(resp => {
        resolve(resp);
      }).catch(error => {
        reject(error);
      });
    });
  },
  checkTempUser: async ({commit, dispatch, state}) => {
    await dispatch('getAllUsers').then(data => {
      const pUser = data.find(u => u.id === state.user.id);
      if(pUser){
        commit('setUser', pUser);
      }
    });
  },
  getUser: async ({commit, dispatch, state}) => {
    await axios().get(routes.userInfo).then(resp => {
      if(user.id) {
        commit('setPrevUser', resp.data.data);
        dispatch('checkTempUser');
      } else {
        commit('setUser', resp.data.data);
      }
      if (resp.data.tokens) {
        dispatch('changeTokens', resp.data.tokens);
      }
    }).catch(error => {
      dispatch('logout');
      console.error('Error get user:', error);
    });
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
