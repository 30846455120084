import axios from '@/api/axios';
import {routes} from '@/api/config';
import router from '../../router';


const state = {};
const getters = {};
const mutations = {};
const actions = {
  getAllArticles: ({commit, dispatch}, {per_page, current_page}) => {
    return new Promise((resolve, reject) => {
      axios().get(routes.getAllArticles, {
        params: {per_page, current_page}
      }).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve({data: resp.data.data, pagination: resp.data.paginationInfo});
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error get all articles', error);
        reject(error);
      });
    });
  },
  getReadyArticles: ({commit, dispatch}, {per_page, current_page}) => {
    return new Promise((resolve, reject) => {
      axios().get(routes.readyArticles, {
        params: {per_page, current_page}
      }).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve({data: resp.data.data, pagination: resp.data.paginationInfo});
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error get ready articles', error);
        reject(error);
      });
    });
  },
  getLastChanges: ({commit, dispatch}, {per_page, current_page, hide_new}) => {
    return new Promise((resolve, reject) => {
      axios().get(routes.lastChanges, {
        params: {per_page, current_page, hide_new}
      }).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve({data: resp.data.data, pagination: resp.data.paginationInfo});
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error get last changes', error);
        reject(error);
      });
    });
  },
  getArticles: ({commit, dispatch}, {per_page, current_page}) => {
    return new Promise((resolve, reject) => {
      axios().get(routes.articles, {
        params: {per_page, current_page}
      }).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve({data: resp.data.data, pagination: resp.data.paginationInfo});
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error get all articles', error);
        reject(error);
      });
    });
  },
  getArticlesUser: ({commit, dispatch}, {per_page, current_page, id, status}) => {
    return new Promise((resolve, reject) => {
      axios().get(routes.articlesUser(id), {
        params: {per_page, current_page, status}
      }).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve({data: resp.data.data, pagination: resp.data.paginationInfo});
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error get all articles', error);
        reject(error);
      });
    });
  },
  setArticleColor: ({commit, dispatch}, {texts_ids, color}) => {
    return new Promise((resolve, reject) => {
      axios().put(routes.setArticleColor, {texts_ids, color}).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error set articles colors', error);
        reject(error);
      });
    });
  },
  createArticle: ({commit, dispatch}, {text, user_id, title, language}) => {
    return new Promise((resolve, reject) => {
      axios().post(routes.articles, {text, user_id, title, language}).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error creating article', error);
        reject(error);
      });
    });
  },
  updateArticle: ({commit, dispatch}, {text, text_id, title, language}) => {
    return new Promise((resolve, reject) => {
      axios().put(routes.articles, {text, text_id, title, language}).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error updating article', error);
        reject(error);
      });
    });
  },
  deleteArticles: ({commit, dispatch}, texts_ids) => {
    return new Promise((resolve, reject) => {
      axios().delete(routes.articles, {
        data: {texts_ids}
      }).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error deleting articles', error);
        reject(error);
      });
    });
  },
  setArticleKeywords: ({commit, dispatch}, {slave, general, id}) => {
    return new Promise((resolve, reject) => {
      axios().post(routes.setArticleKeywords(id), {
        slave, general
      }).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error add article keywords', error);
        reject(error);
      });
    });
  },
  setArticleComment: ({commit, dispatch}, {comment, in_reply_to, id}) => {
    return new Promise((resolve, reject) => {
      axios().post(routes.setArticleComment(id), {
        comment, in_reply_to
      }).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error add comment', error);
        reject(error);
      });
    });
  },
  getArticleComments: ({commit, dispatch}, id) => {
    return new Promise((resolve, reject) => {
      axios().get(routes.articleComments(id)).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error get comments', error);
        reject(error);
      });
    });
  },
  deleteArticleComments: ({commit, dispatch}, id) => {
    return new Promise((resolve, reject) => {
      axios().delete(routes.articleComments(id)).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error delete comments', error);
        reject(error);
      });
    });
  },
  deleteArticlePermanently: ({commit, dispatch}, texts_ids) => {
    return new Promise((resolve, reject) => {
      axios().delete(routes.deletePermanently, {
        data: {texts_ids}
      }).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error delete permanently', error);
        reject(error);
      });
    });
  },
  restoreArticles: ({commit, dispatch}, texts_ids) => {
    return new Promise((resolve, reject) => {
      axios().post(routes.restoreArticles, {texts_ids}).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error restore articles', error);
        reject(error);
      });
    });
  },
  setPublicArticles: ({commit, dispatch}, texts_ids) => {
    return new Promise((resolve, reject) => {
      axios().post(routes.publicArticles, {texts_ids}).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error public articles', error);
        reject(error);
      });
    });
  },
  toggleLockArticle: ({commit, dispatch}, {texts_ids, status}) => {
    return new Promise((resolve, reject) => {
      axios().post(routes.toggleLockArticle, {texts_ids, status}).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error toggle lock article', error);
        reject(error);
      });
    });
  },
  setReadyArticle: ({commit, dispatch}, texts_ids) => {
    return new Promise((resolve, reject) => {
      axios().post(routes.readyArticles, {texts_ids}).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error set ready article', error);
        reject(error);
      });
    });
  },
  getArticle: ({commit, dispatch}, id) => {
    return new Promise((resolve, reject) => {
      axios().get(routes.getArticle(id)).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error get article', error);
        reject(error);
      });
    });
  },
  getDeletedArticles: ({commit, dispatch}, {per_page, current_page}) => {
    return new Promise((resolve, reject) => {
      axios().get(routes.deletedArticles, {
        params: {per_page, current_page}
      }).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve({data: resp.data.data, pagination: resp.data.paginationInfo});
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error get deleted articles', error);
        reject(error);
      });
    });
  },
  getPublicArticles: ({commit, dispatch}, {per_page, current_page}) => {
    return new Promise((resolve, reject) => {
      axios().get(routes.publicArticles, {
        params: {per_page, current_page}
      }).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve({data: resp.data.data, pagination: resp.data.paginationInfo});
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error get public articles', error);
        reject(error);
      });
    });
  },
  getLastComments: ({commit, dispatch}, {per_page, current_page}) => {
    return new Promise((resolve, reject) => {
      axios().get(routes.lastComments, {
        params: {per_page, current_page}
      }).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve({data: resp.data.data, pagination: resp.data.paginationInfo});
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error get last comments', error);
        reject(error);
      });
    });
  },
  copyUserArticles: ({commit, dispatch}, {texts_ids, to}) => {
    return new Promise((resolve, reject) => {
      axios().post(routes.copyArticles, {texts_ids, to}).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error copy articles', error);
        reject(error);
      });
    });
  },
  moveUserArticles: ({commit, dispatch}, {texts_ids, to}) => {
    return new Promise((resolve, reject) => {
      axios().post(routes.moveArticles, {texts_ids, to}).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error copy articles', error);
        reject(error);
      });
    });
  },
  getArticleRevisions: ({commit, dispatch}, id) => {
    return new Promise((resolve, reject) => {
      axios().get(routes.getArticleRevisions(id)).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error get article revisions', error);
        reject(error);
      });
    });
  },
  setArticleRevision: ({commit, dispatch}, id) => {
    return new Promise((resolve, reject) => {
      axios().put(routes.setArticleRevision(id)).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error set article revisions', error);
        reject(error);
      });
    });
  },
  checkArticleMetrics: ({commit, dispatch}, {author_id, text, language, text_id}) => {
    return new Promise((resolve, reject) => {
      axios().post(routes.checkArticle, {author_id, text, language, text_id}).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error check article metrics', error);
        reject(error);
      });
    });
  },
  getTextruBalance: ({commit, dispatch}) => {
    return new Promise((resolve, reject) => {
      axios().get(routes.textruBalance).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error get textru balance', error);
        reject(error);
      });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
