import axios from 'axios';
import {baseUrl} from '@/api/config';
import jwtDecode from 'jwt-decode';

export default () => {
  const options = {
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json'
    },
    timeout: 30000
  };

  const tokens = JSON.parse(localStorage.getItem('tokens'));
  if (tokens) {
    const {accessToken, refreshToken} = tokens;
    options.headers.Authorization = ` ${accessToken}`;
    if (jwtDecode(accessToken).exp < Date.now() / 1000) {
      options.headers['x-refresh-token'] = refreshToken;
    }
  }
  return axios.create(options);
};
