import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

const tokens = JSON.parse(localStorage.getItem('tokens'));

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    name: 'Error',
    component: () => import('@/views/Error.vue')
  },
  {
    path: '/',
    beforeEnter: (to, from, next) => {
      next('/login')
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  // {
  //   path: '/sign-up',
  //   name: 'SignUp',
  //   component: () => import('@/views/SignUp.vue')
  // },
  {
    path: '/dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),

    children: [
      {
        path: '',
        beforeEnter: (to, from, next) => {
          next('/dashboard/articles')
        },
      },
      {
        path: 'articles',
        name: 'Articles',
        component: () => import('@/views/dashboard/articles/ArticlesPage.vue'),
      },
      {
        path: 'articles/create',
        name: 'ArticleCreate',
        component: () => import('@/views/dashboard/articles/ArticlesCreate.vue'),
      },
      {
        path: 'articles/:id',
        name: 'ArticlePage',
        component: () => import('@/views/dashboard/articles/ArticlePage.vue'),
      },
      {
        path: 'last-changes',
        name: 'LastChangesPage',
        component: () => import('@/views/dashboard/last-changes/LastChanges'),
        meta: {
          authorize: ['SUPERADMIN', 'ADMIN', 'SUBADMIN']
        }
      },
      {
        path: 'trashbox',
        name: 'TrashboxPage',
        component: () => import('@/views/dashboard/trashbox/TrashboxPage'),
        meta: {
          authorize: ['SUPERADMIN','ADMIN', 'SUBADMIN']
        }
      },
      {
        path: 'users',
        name: 'UsersPage',
        component: () => import('@/views/dashboard/users/UsersPage'),
        meta: {
          authorize: ['SUPERADMIN', 'ADMIN', 'SUBADMIN']
        }
      },
      // {
      //   path: 'settings',
      //   name: 'SettingsPage',
      //   component: () => import('@/views/dashboard/last-changes/LastChanges'),
      //   // beforeEnter: (to, from, next) => userTypeRedirect(to, from, next, 'subadmin')
      // },
      {
        path: 'last-comments',
        name: 'CommentsPage',
        component: () => import('@/views/dashboard/last-comments/LastComments'),
        meta: {
          authorize: ['SUPERADMIN', 'ADMIN', 'SUBADMIN']
        }
        // beforeEnter: (to, from, next) => userTypeRedirect(to, from, next, 'subadmin')
      },
      {
        path: 'ready',
        name: 'ReadyArticlesPage',
        component: () => import('@/views/dashboard/ready-articles/ReadyArticlesPage'),
        meta: {
          authorize: ['SUPERADMIN', 'ADMIN']
        }
        // beforeEnter: (to, from, next) => userTypeRedirect(to, from, next, 'admin')
      },
      {
        path: 'public',
        name: 'PublicArticlesPage',
        component: () => import('@/views/dashboard/ready-articles/PublicArticlesPage'),
        meta: {
          authorize: ['SUPERADMIN', 'ADMIN']
        }
        // beforeEnter: (to, from, next) => userTypeRedirect(to, from, next, 'admin')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const {authorize} = to.meta;

  //   if (!currentUser) {
  //     // not logged in so redirect to login page with the return url
  //     return next({ path: '/login', query: { returnUrl: to.path } });
  //   }
  //
  //   // check if route is restricted by role
  //   if (authorize.length && !authorize.includes(currentUser.role)) {
  //     // role not authorised so redirect to home page
  //     return next({ path: '/' });
  //   }
  // }
  if(!store.getters.isLogged && to.path !== '/login') {
    return next('/login');
  }
  const prevUser = localStorage.getItem('prevUser');
  if((store.getters.isLogged && !store.getters.user.id) || (prevUser && !store.getters.prevUser.id)) {
    await store.dispatch('getUser').then(() => {
      if (authorize && authorize.indexOf(store.getters.user.role) < 0) {
        next({path: from.path});
      } else {
        next();
      }
    });
  } else {
    if (authorize && authorize.indexOf(store.getters.user.role) < 0) {
      next({path: from.path});
    } else {
      next();
    }
  }

})

export default router
