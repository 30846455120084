import axios from '@/api/axios';
import {routes} from '@/api/config';
import router from '../../router';


const state = {
  selectedUser: null,
  roles: [
    {
      id: 1,
      value: 'SUPERADMIN',
      name: 'SuperAdmin'
    },
    {
      id: 2,
      value: 'ADMIN',
      name: 'Admin'
    },
    {
      id: 3,
      value: 'SUBADMIN',
      name: 'SubAdmin'
    },
    {
      id: 4,
      value: 'USER',
      name: 'Worker'
    }
  ]
};
const getters = {
  selectedUser(state){
    return state.selectedUser
  },
  roles(state){
    return state.roles
  }
};
const mutations = {
  setTempUser(state, user) {
    state.selectedUser = user;
  }
};
const actions = {
  setTempUser: ({commit, dispatch}, user) => {
    commit('setTempUser', user);
  },
  createUser: ({commit, dispatch}, {password, email, user_role}) => {
    return new Promise((resolve, reject) => {
      axios().post(routes.user, {password, email, user_role}).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        reject(error);
      });
    });
  },
  deleteUser: ({commit, dispatch}, id) => {
    return new Promise((resolve, reject) => {
      axios().delete(routes.deleteUser(id)).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        reject(error);
      });
    });
  },
  getSubUsers: ({commit, dispatch}, subadmin_id) => {
    return new Promise((resolve, reject) => {
      axios().get(routes.subUsers, {
        params: {subadmin_id}
      }).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        reject(error);
      });
    });
  },
  setSubUserVisibility: ({commit, dispatch}, {status, subadmin_id, users_ids}) => {
    return new Promise((resolve, reject) => {
      axios().post(routes.subUsers, {status, subadmin_id, users_ids}).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        reject(error);
      });
    });
  },
  changeUserPass: ({commit, dispatch}, {id, password}) => {
    return new Promise((resolve, reject) => {
      axios().post(routes.changeUserPass(id), {password}).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve({data: resp.data.data});
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        reject(error);
      });
    });
  },
  getUsers: ({commit, dispatch}, {per_page, current_page}) => {
    return new Promise((resolve, reject) => {
      axios().get(routes.user, {
        params: {per_page, current_page}
      }).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve({data: resp.data.data, pagination: resp.data.paginationInfo});
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        reject(error);
      });
    });
  },
  getUsersSessions: ({commit, dispatch}, {per_page, current_page, id}) => {
    return new Promise((resolve, reject) => {
      axios().get(routes.userSessions(id), {
        params: {per_page, current_page}
      }).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve({data: resp.data.data, pagination: resp.data.paginationInfo});
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        reject(error);
      });
    });
  },
  changeUserRole: ({commit, dispatch}, {role, id}) => {
    return new Promise((resolve, reject) => {
      axios().put(routes.changeUserRole(id), {role}).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        reject(error);
      });
    });
  },
  changeUserLang: ({commit, dispatch}, {language, id}) => {
    return new Promise((resolve, reject) => {
      axios().put(routes.changeUserLang(id), {language}).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error change user language', error);
        reject(error);
      });
    });
  },
  getAllUsers: ({commit, dispatch}, roles) => {
    return new Promise((resolve, reject) => {
      axios().get(routes.getAllUsers, {
        params: roles && {roles}
      }).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error get all users', error);
        reject(error);
      });
    });
  },
  getUserNotes: ({commit, dispatch}, id) => {
    return new Promise((resolve, reject) => {
      axios().get(routes.userNotes(id)).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error get user notes', error);
        reject(error);
      });
    });
  },
  addUserNote: ({commit, dispatch}, {id, note}) => {
    return new Promise((resolve, reject) => {
      axios().post(routes.addUserNote(id), {note}).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error add user note', error);
        reject(error);
      });
    });
  },
  deleteUserNotes: ({commit, dispatch}, id) => {
    return new Promise((resolve, reject) => {
      axios().delete(routes.userNotes(id)).then(resp => {
        if (resp.data.tokens) {
          dispatch('changeTokens', resp.data.tokens);
        }
        resolve(resp.data.data);
      }).catch(error => {
        if (error.response.tokens) {
          dispatch('changeTokens', error.response.tokens);
        }
        console.error('Error delete user note', error);
        reject(error);
      });
    });
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
